@import '../../assets/style/variables.module.scss';

.formWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-width: 100vw;
  position: relative;
  margin-top: 5vh;

  .content {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;

    .mainTitle {
      color: $--primaryBlue;
      margin-right: 15vh;

      .logo {
        margin-bottom: 2vh;
        svg {
          width: 13.5vh;
          height: 4.2vh;
        }
      }

      h2 {
        font-size: 6vh;
        font-weight: 500;
        font-family: $--Kalnia;
      }

      h3 {
        font-size: 3.5vh;
        font-weight: 400;
        margin-top: 1.5vh;
        line-height: 5vh;
        font-family: $--Kalnia;

        span {
          border-bottom: 0.2vh solid #c0d672;
        }
      }

      h4 {
        font-size: 2vh;
        width: 20vh;
        margin-top: 2vh;
        color: $--text-color;
        line-height: 2.6vh;
      }
    }
  }

  .send {
    background-color: $--primaryBlue;
    color: white;
    padding: 1.2vh 2.7vh;
    align-self: flex-end;
    border-radius: 1vh;
    font-size: 1.8vh;
    width: 100%;
    font-family: $--Kalnia;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 4vh;
    width: 100%;
    align-items: center;
    justify-content: center;

    .left {
      .studentCardWrapper {
        display: flex;
        flex-direction: column;
        gap: 1vh;
        margin-bottom: 2vh;
        margin-top: 2vh;
        .studentCard {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: rgba(17, 12, 46, 0.06) 0px 3px 5px 0px;
          border: 0.2vh solid white;
          background: #f7f3ee;
          padding: 1vh 2vh;
          border-radius: 0.6vh;
          box-sizing: border-box;

          .studentInfo {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.6vh;
            h4 {
              font-size: 1.8vh;
              font-weight: 400;
              color: $--primaryBlue;
            }
            h5 {
              font-size: 1.4vh;
              font-weight: 400;
              color: $--primaryBlue;
              margin-top: 0.2vh;
            }
          }

          .buttonWrapper {
            display: flex;
            gap: 1vh;
            button {
              width: 2.5vh;
              height: 2.5vh;
              padding: 0.1vh;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .studentSave {
        padding: 1vh 2vh;
        background: $--primaryBlue;
        border: 0.2vh solid white;
        box-shadow: rgba(17, 12, 46, 0.06) 0px 3px 5px 0px;
        border-radius: 1vh;
        width: 100%;
        font-family: $--Kalnia;
        font-weight: 400;
        margin-top: 2vh;
        font-size: 1.7vh;
        color: white;
      }

      .newStudent {
        padding: 1.5vh 2.5vh;
        background: #f7f3ee;
        border: 0.2vh solid white;
        box-shadow: rgba(17, 12, 46, 0.06) 0px 3px 5px 0px;
        border-radius: 1vh;
        width: 100%;
        font-family: $--Kalnia;
        font-weight: 400;
        margin-top: 2vh;
        font-size: 1.7vh;
        color: $--primaryBlue;
      }
    }
  }
}

.formCard {
  padding: 5.2vh;
  background: #f7f3ee;
  border: 0.2vh solid white;
  box-shadow: rgba(17, 12, 46, 0.06) 0px 48px 100px 0px;
  border-radius: 1vh;
  display: flex;
  flex-direction: column;
  width: 60vw;

  .title {
    font-size: 2.4vh;
    color: $--primaryBlue;
    font-family: $--Kalnia;
    font-weight: 400;
    margin-bottom: 2vh;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 2vh;
  }
}

.inputLabel {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  span {
    font-size: 2vh;
    color: #fa6848;
    letter-spacing: 0.03em;
  }

  &:focus-within {
    input,
    select {
      border: 1px solid $--primaryBlue;
    }
    h6 {
      color: $--primaryBlue;
    }
  }

  h6 {
    font-size: 2.4vh;
    color: #8f8f8f;
    font-weight: 400;
    letter-spacing: 0.02em;
    transition: all 0.5s ease;
  }

  input,
  select,
  textarea {
    height: 5vh;
    border-radius: 5px;
    border: 1px solid #dcdcdc;
    padding-left: 10px;
    box-sizing: border-box;
    transition: all 0.5s ease;
    width: 100%;
    font-size: 2.6vh;

    &::placeholder {
      color: #b1b1b1;
      font-size: 2vh;
    }
  }

  textarea {
    resize: none;
    padding-top: 0.5vh;
    height: 7vh;
  }

  select {
    font-size: 2.6vh;
  }
}

.split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2vh;
}

.terms {
  display: flex;
  gap: 5px;
  align-items: center;
  p {
    color: #8f8f8f;
    font-size: 2vh;
    max-width: 50vh;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
    color: $--primaryBlue;
  }
  .check {
    width: 2vh;
    height: 2vh;
    min-width: 2vh;
    min-height: 2vh;
    background: white;
    border: 0.1vh solid #dcdcdc;
    border-radius: 0.3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .activeCheck {
    background: $--primaryBlue;
    &::before {
      content: '✓';
      color: white;
      font-size: 1.2vh;
    }
  }
}

.formBottom {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  gap: 1.5vh;
}

.success {
  width: 100vw;
  height: 100vw;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 2.7vh;
    text-align: center;
    color: $--primaryBlue;
    line-height: 5vh;

    span {
      font-size: 5vh;
      font-weight: 600;
    }
  }
}

.error {
  font-size: 1.4vh;
  color: #fa6848;
  letter-spacing: 0.03em;
}

.checkWrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  .checkTitle {
    text-align: left;
    width: 100%;

    h5 {
      font-size: 2.4vh;
      color: #8f8f8f;
      font-weight: 400;
      letter-spacing: 0.02em;
    }

    span {
      display: block;
      height: 5vh;
      border-radius: 5px;
      border: 1px solid #dcdcdc;
      background: white;
      width: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 1vh;
      font-size: 2.6vh;
      margin-top: 5px;
    }
  }

  .checkCard {
    position: absolute;
    left: 0;
    top: 8.5vh;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: 1px solid #dcdcdc;
    background: white;
    padding: 2vh;
    border-radius: 1vh;
    z-index: 100;

    label {
      display: flex;
      gap: 5px;

      input {
        accent-color: $--primaryBlue;
      }

      h5 {
        font-size: 2.4vh;
        font-weight: 400;
      }
    }
  }
}

.fileWrapper {
  label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    cursor: pointer;

    input {
      height: 100%;
      width: 100;
      opacity: 0;
      cursor: pointer;
      position: absolute;
    }

    .field {
      min-height: 5vh;
      border: 1px solid #dcdcdc;
      background: white;
      padding-left: 2vh;
      border-radius: 1vh;
      display: flex;
      align-items: center;
      gap: 3px;

      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        object-position: center;
        margin: 2vh 0;
        border-radius: 0.5vh;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    h5 {
      font-size: 2.4vh;
      color: #8f8f8f;
      font-weight: 400;
      letter-spacing: 0.02em;
    }
  }
}

.error {
  font-size: 2vh;
  color: #fa6848;
  letter-spacing: 0.03em;
  margin-top: 5px;
}

@media only screen and (max-width: 940px) {
  .formWrapper {
    align-items: flex-start;
    padding: 5vh 0;
    height: auto;
    max-height: unset;
    min-height: unset;
    box-sizing: border-box;
    padding-bottom: 25vh;
    position: relative;
    z-index: 1;
    top: -7vh;
    background: #f7f3ee;

    .content {
      flex-direction: column;
      box-sizing: border-box;
      width: 90vw;

      .mainTitle {
        margin-right: unset;
        margin-bottom: 3vh;
        h2 {
          font-size: 4vh;
        }
        h3 {
          font-size: 2.6vh;
        }
      }

      form {
        flex-direction: column;
        align-self: center;

        .formCard {
          padding: 3vh 2vh;
          width: 100%;
          max-width: unset;
          box-sizing: border-box;
        }
      }

      .inputLabel {
      }
    }

    .send {
      margin-top: 2vh;
    }
  }
}
