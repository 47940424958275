@import '../../assets/style/variables.module.scss';

.navWrapper {
  width: 9.1vh;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 100;

  button {
    cursor: pointer;
  }
}

.navbar {
  width: 9.1vh;
  height: 100%;
  background: #f7f3ee;
  border-right: 1px solid #b9d36c;
  border-left: 1px solid #b9d36c;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 3vh;
  padding-bottom: 3vh;
  box-sizing: border-box;
  transition: all 0.1s ease;

  button {
    cursor: pointer;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      width: 5.3vh;
      height: 5.3vh;
    }
    ul {
      display: flex;
      gap: 3.7vh;
      transform: rotate(90deg);
      margin-bottom: 23vh;

      li {
        font-size: 1.4vh;
        color: $--primaryBlue;
        text-wrap: nowrap;
        transition: all 0.5s ease;
        min-width: 8vh;
        text-align: center;
      }
    }
  }
}

.active {
  background: $--primaryBlue !important;

  .bottom {
    ul {
      li {
        color: white;
      }
    }
  }
}

.white {
  button {
    svg {
      line {
        stroke: white;
      }
    }
  }
  .bottom {
    ul {
      li {
        color: white;
      }
    }
  }
}

.transparent {
  button {
    svg {
      line {
        stroke: white;
      }
    }
  }
  .bottom {
    ul {
      li {
        color: white;
      }
    }
  }
}

.navContent {
  width: calc(100vw - 9.1vh);
  height: 100vh;
  min-width: calc(100vw - 9.1vh);
  min-height: 100vh;
  background: $--primaryBlue;
  position: absolute;
  z-index: 99;
  right: 0;
  padding-top: 10vh;
  padding-bottom: 5vh;
  padding-left: 15vh;
  padding-right: 15vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .content {
    height: 100%;
    width: 100%;
    justify-content: space-between;

    display: flex;
    flex-direction: column;
    .nasil {
      margin-bottom: 5vh;
      .nasilSvg {
        svg {
          height: 4.2vh;
          width: 23vh;
        }
      }

      ul {
        margin-left: -2vh;
        display: flex;
        flex-direction: column;
        gap: 3vh;
        margin-top: 4vh;

        li {
          svg {
            path {
              transition: all 0.5s ease;
            }
          }
          &:hover {
            svg {
              path {
                fill: #b9d36c;
              }
            }
          }
          svg {
            height: 7.3vh;
          }

          &:nth-child(1) {
            svg {
              width: 31.4vh;
              height: 5vh;
            }
          }

          &:nth-child(2) {
            svg {
              width: 43.4vh;
            }
          }
          &:nth-child(3) {
            svg {
              width: 50.4vh;
            }
          }
          &:nth-child(4) {
            svg {
              width: 29.4vh;
              height: 5vh;
            }
          }
        }
      }
    }

    .bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      .contactInfo {
        position: absolute;
        right: 0;
        bottom: 10vh;
        font-size: 3vh;
        color: white;
        transition: all 0.5s ease;
        text-align: right;
        line-height: 3.4vh;

        span {
          font-weight: 300;
          text-decoration: underline;
        }

        a {
          color: $--secondaryGreen;
        }
      }

      button {
        margin-bottom: 2vh;
        font-size: 6.5vh;
        font-weight: 400;
        color: white;
        transition: all 0.5s ease;
        text-decoration: underline;

        &:hover {
          color: #b9d36c;
        }
      }

      .social {
        border-top: 0.3vh solid #c0d672;
        padding-top: 2vh;
        align-self: flex-end;
        width: 100%;

        .bayetav {
          width: 15vh;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;

          img {
            width: 100%;
          }
        }

        ul {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          gap: 2vh;
          position: relative;

          li {
            &:last-child {
              a {
                svg {
                  width: 15vh;
                  height: 5.2vh;
                }
              }
            }
            a {
              svg {
                height: 3vh;
                width: 3vh;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .navWrapper {
    left: unset;
    bottom: 0;
    height: 9vh;
    .navbar {
      width: 100%;
      height: 9vh;
      flex-direction: row-reverse;
      border-top: 1px solid #b9d36c;
      border-bottom: 1px solid #b9d36c;
      border-right: unset !important;
      border-left: unset !important;
      box-sizing: border-box;
      justify-content: space-between;
      left: 0;

      transform: unset !important;

      button {
        margin-right: 2vh;
      }

      .bottom {
        flex-direction: row-reverse;
        box-sizing: border-box;
        justify-content: flex-end;
        padding-left: 1vh;
        ul {
          transform: unset;
          gap: 10px;
          margin-bottom: unset;
          margin-left: 2vh;
          li {
            min-width: unset;
          }
        }
      }
    }
  }

  .navContent {
    left: 0;
    width: 100vw;
    height: 90vh;
    min-height: unset;
    max-height: unset;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-bottom: 3vh;
    padding-top: 3vh;
    transform: translateY(-9vh);

    .content {
      align-items: flex-end;
      .nasil {
        margin-bottom: unset;
        scale: 0.7;
        top: 6vh;
        position: relative;
        left: 0vh;

        .nasilSvg {
        }

        ul {
          margin-left: 0;

          li {
            &:nth-child(1) {
              svg {
              }
            }

            &:nth-child(2) {
              svg {
                width: 43.4vh;
              }
            }
            &:nth-child(3) {
              svg {
                width: 50.4vh;
              }
            }
            &:nth-child(4) {
              svg {
                width: 29.4vh;
              }
            }
          }
        }
      }

      .bottom {
        .contactInfo {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: -2vh;
        }

        button {
          font-size: 5vh;
        }

        .sss {
          font-size: 3vh;
        }

        .social {
          align-items: center;
          .bayetav {
            width: 80px !important;
          }

          li {
            &:last-child {
              svg {
                height: 30px !important;
                width: unset !important;
              }
            }
          }
          a {
            svg {
              width: 25px !important;
              height: 25px !important;
            }
          }
        }
      }
    }
  }
}
