@import '../../assets/style/variables.module.scss';

.nasilOkul {
  width: 200vw;
  height: 100vh;
  min-width: 200vw;
  display: flex;
  position: relative;

  .part1 {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;

    display: flex;
    padding-left: 20vh;
    padding-top: 8.8vh;
    position: relative;
    box-sizing: border-box;

    .top {
      display: flex;
      flex-direction: column;
      gap: 5.19vh;

      .logo {
        svg {
          width: 13.5vh;
          height: 4.2vh;
        }
      }

      .svgWrapper {
        display: flex;
        flex-direction: column;

        svg {
          &:nth-child(1) {
            width: 43vh;
            height: 7.7vh;
          }
          &:nth-child(2) {
            margin-left: 5.2vh;
            width: 90vh;
            height: 27vh;
          }
        }
      }
    }

    .info {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 8vh;
      left: 20vh;
      gap: 7vh;

      a {
        color: $--primaryBlue;
        text-decoration: underline;
      }

      p {
        color: $--text-color;
        font-size: 2.3vh;
        line-height: 2.7vh;
        font-weight: 400;

        &:last-child {
          border: 0.1vh solid $--primaryBlue;
          padding: 1vh;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
  }

  .part2 {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    padding-left: 20vh;
    padding-top: 8.8vh;
    position: relative;
    box-sizing: border-box;

    .row {
      display: flex;
      flex-direction: column;
      gap: 4vh;

      &:nth-child(1) {
        .column {
          &:first-child {
            svg {
              height: 11vh;
              width: 71vh;
            }
          }
          &:last-child {
            svg {
              height: 11vh;
              width: 27.2vh;
            }
          }
        }
      }

      &:nth-child(2) {
        margin-top: 9.8vh;

        .column {
          &:nth-child(2) {
            margin-left: 45vh;
            svg {
              width: 49vh;
              height: 11vh;
            }
          }

          &:first-child {
            svg {
              width: 83.5vh;
              height: 11vh;
            }
          }
        }
      }

      .column {
        display: flex;
        gap: 4vh;

        p {
          color: white;
          font-size: 1.9vh;
          line-height: 2.3vh;
          width: 30vh;
          font-weight: 400;
        }
      }
    }

    .infoRow {
      display: flex;
      position: absolute;
      bottom: 8vh;
      left: 20vh;
      gap: 7vh;

      p {
        color: white;
        font-size: 1.9vh;
        line-height: 2.3vh;
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .nasilOkul {
    width: 100vw;
    height: auto;
    max-height: auto;
    flex-direction: column;

    .part1 {
      padding: 3vh;
      min-height: 100vh;
      max-height: 90vh;
      min-height: 90vh;
      height: 90vh;

      .top {
        .svgWrapper {
          svg {
            &:nth-child(1) {
              height: 3vh;
              width: 18vh;
            }
            &:nth-child(2) {
              height: 30vw;
              width: 80vw;
              margin-left: 3vh;
            }
          }
        }
      }

      .info {
        left: 3vh;
        flex-direction: column;
        gap: 3vh;
        bottom: 15vh;
        align-items: flex-start;
      }
    }

    .part2 {
      width: 100vw;
      min-width: 100vw;
      padding-left: 0;
      background: $--primaryBlue;
      flex-direction: column;
      min-height: 100vh;
      height: auto;

      .row {
        position: static;
        gap: 2vh;

        p {
          font-size: 18px !important;
          line-height: 24px !important;
        }

        &:nth-child(1) {
          .column {
            gap: 2vh;
            &:first-child {
              svg {
                scale: 0.9;
              }
            }
            &:last-child {
              margin-left: 0;
              svg {
                scale: 0.8;
              }
            }
          }
        }

        &:nth-child(2) {
          margin-top: 3vh;
          margin-left: 0;

          .column {
            gap: 0vh;
            &:nth-child(2) {
              margin-left: 0;
              svg {
                scale: 0.8;
                position: relative;
                top: -3vh;
              }
            }

            &:first-child {
              svg {
                scale: 0.9;
              }
            }
          }
        }
      }

      .infoRow {
        position: static;
        left: 0;
        bottom: 25vh;
        flex-direction: column;
        padding-left: 3.2vh;
        gap: 2vh;
      }
    }
  }
}
