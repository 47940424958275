@import '../../assets/style/variables.module.scss';

.brosur {
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  padding-bottom: 10vh;
  box-sizing: border-box;

  .content {
    padding-left: calc(10vh + 89px);
    box-sizing: border-box;
    padding-top: 75px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding-right: calc(10vh + 89px);

    h1 {
      font-size: 42px;
      font-family: $--Kalnia;
      font-weight: 500;
      color: $--primaryBlue;
    }

    .pdfWrapper {
      align-self: center;
      box-sizing: border-box;
      margin-top: 3vh;

      .pdf {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border: 1px solid $--secondaryGreen;
      }

      .emptyPage {
        background: white;
      }
    }

    .pdfNavigate {
      position: absolute;
      bottom: 5vh;
      align-self: center;
      display: flex;
      align-items: center;
      width: 20vh;
      justify-content: space-between;
      z-index: 20;
      background: white;
      padding: 3vh;
      border-radius: 1vh;
      border: 1px solid #dbdbdb;

      .pages {
        font-family: $--Kalnia;
        font-size: 3vh;
        color: $--primaryBlue;
      }

      button {
        width: 4vh;
        height: 4vh;
        border: 1px solid $--secondaryGreen;
        font-size: 3vh;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #575757;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .brosur {
    .content {
      padding-left: unset;
      padding: 40px 20px;
      padding-bottom: 150px;

      h1 {
        font-size: 22px;
      }

      .pdfWrapper {
        margin-top: 1vh;
        .pdf {
          flex-direction: column;
          height: 120vh;
          justify-content: flex-start;
        }
      }

      .pdfNavigate {
        right: 20px;
        z-index: 20;
        bottom: 10vh;
        padding: 1.5vh;
        width: auto;

        .pages {
          font-size: 2.5vh;
          margin: 0 1vh;
        }
      }
    }
  }
}
