@import '../../assets/style/variables.module.scss';

.home {
  display: flex;
  max-height: 100vh;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 14000vw;
  transform: translateY(200);
}

@media only screen and (max-width: 940px) {
  .home {
    width: 100vw;
    height: auto;
    flex-direction: column;
    overflow-y: scroll;
  }
}
