@import '../../assets/style/variables.module.scss';

.formWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  position: relative;

  .content {
    display: flex;
    justify-content: space-between;

    .mainTitle {
      color: $--primaryBlue;
      margin-right: 15vh;

      .logo {
        margin-bottom: 2vh;
        svg {
          width: 13.5vh;
          height: 4.2vh;
        }
      }

      h2 {
        font-size: 6vh;
        font-weight: 500;
        font-family: $--Kalnia;
      }

      h3 {
        font-size: 3.5vh;
        font-weight: 400;
        margin-top: 1.5vh;
        line-height: 5vh;
        font-family: $--Kalnia;

        span {
          border-bottom: 0.2vh solid #c0d672;
        }
      }

      h4 {
        font-size: 2vh;
        width: 20vh;
        margin-top: 2vh;
        color: $--text-color;
        line-height: 2.6vh;
      }
    }
  }

  .send {
    background-color: $--primaryBlue;
    color: white;
    padding: 1.2vh 2.7vh;
    align-self: flex-end;
    border-radius: 1vh;
    font-size: 1.8vh;
    width: 100%;
    font-family: $--Kalnia;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 4vh;
    min-width: 90vh;
    min-height: 80vh;

    .stepWrapper {
      display: flex;
      flex-direction: column;
      transform: translateX(200vh);
      transform: translateY(-100vh);
      transition: all 0.5s ease;
      opacity: 0;
      height: 0;
      position: absolute;

      h2 {
        font-family: $--Kalnia;
        font-size: 4.4vh;
        font-weight: 400;
        color: $--primaryBlue;
        margin-bottom: 4vh;
      }

      .formContent {
        display: flex;
        flex-direction: column;
        gap: 2.5vh;
      }
    }

    .activeStep {
      transform: translateX(0);
      transform: translateY(0);

      opacity: 1;
      height: auto;
      position: static;
    }

    .buttonWrapper {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      gap: 2.5vh;

      button {
        font-size: 3.2vh;
        display: flex;
        align-items: center;
        letter-spacing: 0.1vh;
        gap: 1.4vh;
      }

      .continue {
        background: $--primaryBlue;
        padding: 1vh 4vh;
        width: 100%;
        border-radius: 5px;
        color: white;
        align-items: center;
        justify-content: center;
      }

      .back {
        color: $--primaryBlue;
      }
    }

    .left {
      .studentCardWrapper {
        display: flex;
        flex-direction: column;
        gap: 1vh;
        margin-bottom: 2vh;
        margin-top: 2vh;
        .studentCard {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: rgba(17, 12, 46, 0.06) 0px 3px 5px 0px;
          border: 0.2vh solid white;
          background: #f7f3ee;
          padding: 1vh 2vh;
          border-radius: 0.6vh;
          box-sizing: border-box;

          .studentInfo {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.6vh;
            h4 {
              font-size: 1.8vh;
              font-weight: 400;
              color: $--primaryBlue;
            }
            h5 {
              font-size: 1.4vh;
              font-weight: 400;
              color: $--primaryBlue;
              margin-top: 0.2vh;
            }
          }

          .buttonWrapper {
            display: flex;
            gap: 1vh;
            button {
              width: 2.5vh;
              height: 2.5vh;
              padding: 0.1vh;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .studentSave {
        padding: 1vh 2vh;
        background: $--primaryBlue;
        border: 0.2vh solid white;
        box-shadow: rgba(17, 12, 46, 0.06) 0px 3px 5px 0px;
        border-radius: 1vh;
        width: 100%;
        font-family: $--Kalnia;
        font-weight: 400;
        margin-top: 2vh;
        font-size: 1.7vh;
        color: white;
      }

      .newStudent {
        padding: 1.5vh 2.5vh;
        background: #f7f3ee;
        border: 0.2vh solid white;
        box-shadow: rgba(17, 12, 46, 0.06) 0px 3px 5px 0px;
        border-radius: 1vh;
        width: 100%;
        font-family: $--Kalnia;
        font-weight: 400;
        margin-top: 2vh;
        font-size: 1.7vh;
        color: $--primaryBlue;
      }
    }
  }
}

.formCard {
  padding: 3.2vh 5.2vh;
  background: #f7f3ee;
  border: 0.2vh solid white;
  box-shadow: rgba(17, 12, 46, 0.06) 0px 48px 100px 0px;
  border-radius: 1vh;
  max-width: 40vh;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 2.4vh;
    color: $--primaryBlue;
    font-family: $--Kalnia;
    font-weight: 400;
    margin-bottom: 2vh;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.inputLabel {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 100%;

  span {
    font-size: 2vh;
    color: #fa6848;
    letter-spacing: 0.03em;
  }

  &:focus-within {
    input,
    select {
      border: 1px solid $--primaryBlue;
    }
    h6 {
      color: $--primaryBlue;
    }
  }

  h6 {
    font-size: 2.4vh;
    color: #8f8f8f;
    font-weight: 400;
    letter-spacing: 0.02em;
    transition: all 0.5s ease;
  }

  input,
  select,
  textarea {
    height: 6vh;
    border-radius: 5px;
    border: 1px solid #dcdcdc;
    padding-left: 10px;
    box-sizing: border-box;
    transition: all 0.5s ease;
    width: 100%;
    font-size: 2.4vh;

    &::placeholder {
      color: #b1b1b1;
      font-size: 2vh;
    }
  }

  .radioWrapper {
    display: flex;
    gap: 2vh;
    height: 6vh;

    input {
      width: 3vh;
      height: 3vh;
      background-color: blue;
    }

    label {
      display: flex;
      align-items: center;
      gap: 1vh;

      h5 {
        font-size: 2vh;
        color: rgb(48, 48, 48);
      }
    }

    input[type='radio']:after {
      width: 100% !important;
      min-width: 100% !important;
      height: 100% !important;
      border-radius: 100%;
      top: -2px;
      left: -1px;
      position: relative;
      background-color: #d1d3d1;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 2px solid white;
    }

    input[type='radio']:checked:after {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      top: -2px;
      left: -1px;
      position: relative;
      background-color: $--primaryBlue;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 2px solid white;
    }
  }

  textarea {
    resize: none;
    padding-top: 0.5vh;
    height: 12vh;
  }

  select {
    font-size: 2.4vh;
  }
}

.split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5vh;
}

.terms {
  display: flex;
  gap: 5px;
  align-items: center;
  p {
    color: #8f8f8f;
    font-size: 2.4vh;
    max-width: 60vh;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
    color: $--primaryBlue;
  }
  .check {
    width: 3vh;
    height: 3vh;
    min-width: 3vh;
    min-height: 3vh;
    background: white;
    border: 0.1vh solid #dcdcdc;
    border-radius: 0.3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .activeCheck {
    background: $--primaryBlue;
    &::before {
      content: '✓';
      color: white;
      font-size: 2vh;
    }
  }
}

.formBottom {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  gap: 1.5vh;
}

.success {
  width: 100vw;
  height: 100vw;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 2.7vh;
    text-align: center;
    color: $--primaryBlue;
    line-height: 5vh;

    span {
      font-size: 5vh;
      font-weight: 600;
    }
  }
}

.error {
  font-size: 2vh;
  color: #fa6848;
  letter-spacing: 0.03em;
  align-self: flex-end;
}

@media only screen and (max-width: 940px) {
  .formWrapper {
    align-items: flex-start;
    padding: 5vh 0;
    height: auto;
    max-height: unset;
    min-height: unset;
    box-sizing: border-box;
    padding-bottom: 17vh;
    position: relative;
    z-index: 1;
    top: -7vh;
    background: #f7f3ee;

    .content {
      flex-direction: column;
      box-sizing: border-box;
      width: 90vw;

      .mainTitle {
        margin-right: unset;
        margin-bottom: 3vh;
        h2 {
          font-size: 4vh;
        }
        h3 {
          font-size: 2.6vh;
        }
      }

      form {
        flex-direction: column;
        align-self: center;

        width: 100%;
        min-width: unset;
        max-width: 100vw;

        .stepWrapper {
          .formContent {
            flex-direction: column;

            .split {
              display: flex;
              flex-direction: column;
            }
          }
        }

        .buttonWrapper {
          flex-direction: column;

          .continue {
            padding: 1vh 0;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
        }

        .formCard {
          padding: 3vh 2vh;
          width: 100%;
          max-width: unset;
          box-sizing: border-box;
        }
      }

      .inputLabel {
        .radioWrapper {
          display: flex;
          gap: 2vh;
          height: 6vh;

          input {
            width: 4vh;
            height: 4vh;
            background-color: blue;
          }

          label {
            display: flex;
            align-items: center;
            gap: 1vh;

            h5 {
              font-size: 3vh;
              color: rgb(48, 48, 48);
            }
          }

          input[type='radio']:after {
            display: none !important;
          }

          input[type='radio']:checked:after {
            display: none !important;
          }
        }
      }
    }

    .send {
      margin-top: 2vh;
    }
  }
}
