@import '../../assets/style/variables.module.scss';

.nasilOgrenci {
  width: 200vw;
  height: 100vh;
  min-width: 200vw;
  display: flex;
  position: relative;

  .part1 {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    display: flex;
    padding-left: 20vh;
    padding-top: 8.8vh;
    position: relative;
    box-sizing: border-box;
    background: $--primaryBlue;

    .top {
      display: flex;
      flex-direction: column;
      gap: 5.19vh;

      .logo {
        svg {
          width: 13.5vh;
          height: 4.2vh;
        }
      }

      .svgWrapper {
        display: flex;
        flex-direction: column;

        svg {
          &:nth-child(1) {
            width: 43vh;
            height: 7.7vh;
          }
          &:nth-child(2) {
            margin-left: 5.2vh;
            width: 90vh;
            height: 27vh;
          }
        }
      }
    }

    .info {
      display: flex;
      position: absolute;
      align-items: center;
      bottom: 8vh;
      left: 20vh;
      gap: 7vh;

      p {
        color: white;
        font-size: 2.3vh;
        line-height: 2.7vh;
        font-weight: 400;

        &:last-child {
          border: 0.1vh solid white;
          padding: 1vh;
          box-sizing: border-box;
          cursor: pointer;
        }
      }

      a {
        color: white;
        text-decoration: underline;
      }
    }
  }

  .part2 {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    padding-top: 8.8vh;
    position: relative;
    box-sizing: border-box;

    .row {
      display: flex;
      margin-bottom: 8vh;

      &:nth-child(4) {
        align-items: flex-end;
        gap: 2vh;
        padding-left: 12vh;

        .column {
          svg {
            height: 14.3vh;
            width: 80.7vh;
          }
          p {
            color: $--primaryBlue;
            font-size: 1.9vh;
            line-height: 2.3vh;
            font-weight: 400;
            margin-top: -6.5vh;
          }
        }
      }

      &:nth-child(3) {
        .columnWrapper {
          flex-direction: row;
        }
      }

      &:last-child {
        padding-left: 20vh;
      }

      .stroke {
        background: $--secondaryGreen;
        height: 0.2vh;
        margin-right: 4vh;
      }
      .columnWrapper {
        display: flex;
        flex-direction: column;
        .column {
          display: flex;

          p {
            color: $--primaryBlue;
            font-size: 1.9vh;
            line-height: 2.3vh;
            font-weight: 400;
          }

          &:nth-child(2) {
            p {
              margin-left: 1.5vh;
            }
          }
        }
      }

      &:nth-child(1) {
        .stroke {
          width: 76vh;
        }
        .column {
          &:nth-child(2) {
            margin-left: 4.5vh;
          }
        }
      }
      &:nth-child(2) {
        .stroke {
          width: 45vh;
        }
        .column {
          &:nth-child(2) {
            margin-left: 4.5vh;
            margin-top: 1vh;
          }
        }
      }
      &:nth-child(3) {
        .stroke {
          width: 64vh;
        }
        .column {
          &:nth-child(2) {
            margin-left: 1.2vh;
            margin-top: 1vh;
          }
        }
      }
    }
  }
}

.space {
  height: 1vh;
}

@media only screen and (max-width: 940px) {
  .nasilOgrenci {
    width: 400vw;
    .part1 {
      padding: 3vh;

      .top {
        .svgWrapper {
          svg {
            &:nth-child(1) {
              height: 3vh;
              width: 18vh;
            }
            &:nth-child(2) {
              height: 30vw;
              width: 80vw;
              margin-left: 3vh;
            }
          }
        }
      }

      .info {
        left: 3vh;
        flex-direction: column;
        gap: 3vh;
        bottom: 15vh !important;
        align-items: flex-start;
      }
    }

    .part2 {
      width: 500vw;
      min-width: 500vw;
      max-width: 500vw;
      .row {
        &:nth-child(1) {
          .stroke {
            width: 10vw;
          }
        }
        &:nth-child(2) {
          .stroke {
            width: 40vw;
          }
        }
        &:nth-child(3) {
          .stroke {
            width: 70vw;
          }
        }

        &:nth-child(4) {
          align-items: flex-end;
          flex-direction: column;
          gap: 2vh;
          padding-left: unset;
          position: absolute;
          bottom: 26vh;
          right: 170vw;

          .column {
            svg {
              height: 14.3vh;
              width: 80.7vh;
              scale: 0.5;
            }
            p {
              color: $--primaryBlue;
              font-size: 1.9vh;
              line-height: 2.3vh;
              font-weight: 400;
              margin-top: -6.5vh;
              margin-right: 30vw;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .nasilOgrenci {
    width: 100vw;
    height: auto;
    max-height: auto;
    flex-direction: column;

    .part1 {
      padding: 3vh;
      height: 100vh;
      min-height: 100vh;
      max-height: 90vh;
      min-height: 90vh;
      height: 90vh;

      .top {
        .svgWrapper {
          svg {
            &:nth-child(1) {
              height: 3vh;
              width: 18vh;
            }
            &:nth-child(2) {
              height: 30vw;
              width: 80vw;
              margin-left: 3vh;
            }
          }
        }
      }

      .info {
        left: 3vh;
        flex-direction: column;
        gap: 3vh;
        bottom: 20vh;
      }
    }

    .part2 {
      width: 100vw;
      min-width: 100vw;
      padding-left: 0;
      background: white;
      flex-direction: column;
      min-height: 100vh;

      height: auto;

      .row {
        display: flex;
        margin-bottom: 8vh;

        .stroke {
          width: 5vw !important;
        }

        p {
          font-size: 18px !important;
          line-height: 24px !important;
        }

        .columnWrapper {
          flex-direction: column !important;
          gap: 1vh;
        }

        .column {
          flex-direction: column !important;
          gap: 3vh;
        }

        &:nth-child(4) {
          align-items: flex-end;
          gap: 2vh;
          flex-direction: column;
          position: static;
          margin-left: 60vh;
          margin-top: -6vh;

          .column {
            svg {
              height: 14.3vh;
              width: 70.7vh;
            }
            p {
              color: $--primaryBlue;
              font-size: 1.9vh;
              line-height: 2.3vh;
              font-weight: 400;
              width: 65vw;
            }
          }
        }

        &:nth-child(3) {
          .columnWrapper {
            flex-direction: row;
            p {
              transform: translateX(3vh);
            }
          }
        }

        .stroke {
          background: $--secondaryGreen;
          height: 0.2vh;
          margin-right: 4vh;
        }
        .columnWrapper {
          display: flex;
          flex-direction: column;
          .column {
            display: flex;

            p {
              color: $--primaryBlue;
              font-size: 1.9vh;
              line-height: 2.3vh;
              font-weight: 400;
            }

            &:nth-child(2) {
              p {
                margin-left: 1.5vh;
              }
            }
          }
        }

        &:nth-child(1) {
          .stroke {
            width: 76vh;
          }
          .column {
            &:nth-child(2) {
              margin-left: 4.5vh;
            }
          }
        }
        &:nth-child(2) {
          .stroke {
            width: 45vh;
          }
          .column {
            &:nth-child(2) {
              margin-left: 4.5vh;
              margin-top: 1vh;
            }
          }
        }
        &:nth-child(3) {
          .stroke {
            width: 64vh;
          }
          .column {
            &:nth-child(2) {
              margin-left: 1.2vh;
              margin-top: 1vh;
            }
          }
        }
      }

      .infoRow {
        position: static;
        left: 0;
        bottom: 25vh;
        flex-direction: column;
        padding-left: 3.2vh;
        gap: 2vh;
      }
    }
  }
}
