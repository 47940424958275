@import '../../assets/style/variables.module.scss';

.poemWrapper {
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 50px;
    height: 80vh;
  }

  .poem {
    color: #1b1b1b;
    h3 {
      font-size: 42px;
      line-height: 52px;
    }

    p {
      font-size: 18px;
      line-height: 24px;
      margin-top: 20px;
    }

    h4 {
      margin-top: 30px;
    }
  }
}
