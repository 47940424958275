.videoWrapper {
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  .content {
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4vh;
      align-items: center;

      svg {
        &:nth-child(1) {
          height: 4.4vh;
          width: 13.4vh;
        }
        &:nth-child(2) {
          height: 3.7vh;
          width: 43vh;
        }
      }
    }
  }

  .videoContent {
    width: 71vw;
    height: 74vh;
    cursor: pointer;

    .cover {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        position: absolute;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    video,
    iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.frame {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 940px) {
  .videoWrapper {
    max-height: unset;
    height: 60vh;
    min-height: 60vh;
    .content {
      width: 90vw;
      min-height: unset;
      max-height: unset;
      justify-content: center;

      .videoContent {
        height: 30vh;
        width: 100%;

        video {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
