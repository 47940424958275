@import '../../assets/style/variables.module.scss';

.nasilVeli {
  width: 200vw;
  height: 100vh;
  min-width: 200vw;
  display: flex;
  position: relative;

  .part1 {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    display: flex;
    padding-left: 20vh;
    padding-top: 8.8vh;
    position: relative;
    box-sizing: border-box;

    .top {
      display: flex;
      flex-direction: column;
      gap: 5.19vh;

      .logo {
        svg {
          width: 13.5vh;
          height: 4.2vh;
        }
      }

      .svgWrapper {
        display: flex;
        flex-direction: column;

        svg {
          &:nth-child(1) {
            width: 43vh;
            height: 7.7vh;
          }
          &:nth-child(2) {
            margin-left: 5.2vh;
            width: 90vh;
            height: 27vh;
          }
        }
      }
    }

    .info {
      display: flex;
      position: absolute;
      align-items: center;
      bottom: 8vh;
      left: 20vh;
      gap: 7vh;

      a {
        color: $--primaryBlue;
        text-decoration: underline;
      }

      p {
        color: $--text-color;
        font-size: 2.3vh;
        line-height: 2.7vh;
        font-weight: 400;

        &:last-child {
          border: 0.1vh solid $--primaryBlue;
          padding: 1vh;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
  }

  .part2 {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;

    .bow {
      svg {
        height: 65vh;
      }
    }

    .content {
      display: flex;
      align-items: center;
      gap: 6vh;

      ul {
        display: flex;
        flex-direction: column;
        gap: 6vh;

        li {
          color: $--primaryBlue;

          span {
            font-weight: 600;
          }
          &:nth-child(2),
          &:nth-child(8) {
            margin-left: 16vh;
          }
          &:nth-child(3),
          &:nth-child(7) {
            margin-left: 25vh;
          }
          &:nth-child(4),
          &:nth-child(6) {
            margin-left: 32vh;
          }
          &:nth-child(5) {
            margin-left: 40vh;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .nasilVeli {
    width: 300vw;
    .part1 {
      padding: 3vh;

      .top {
        .svgWrapper {
          svg {
            &:nth-child(1) {
              height: 3vh;
              width: 18vh;
            }
            &:nth-child(2) {
              height: 30vw;
              width: 80vw;
              margin-left: 3vh;
            }
          }
        }
      }

      .info {
        left: 3vh;
        flex-direction: column;
        gap: 3vh;
        bottom: 30vh;
      }
    }

    .part2 {
      width: 200vw;
      min-width: 200vw;
      max-width: 200vw;

      .bow {
        .svg {
          height: 20vh;
        }
      }
      .content {
        scale: 0.8;
        margin-bottom: 20vh;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .nasilVeli {
    width: 100vw;
    height: auto;
    max-height: auto;
    flex-direction: column;

    .part1 {
      padding: 3vh;
      height: 100vh;
      min-height: 100vh;
      max-height: 90vh;
      min-height: 90vh;
      height: 90vh;

      .top {
        .svgWrapper {
          svg {
            &:nth-child(1) {
              height: 3vh;
              width: 18vh;
            }
            &:nth-child(2) {
              height: 30vw;
              width: 80vw;
              margin-left: 3vh;
            }
          }
        }
      }

      .info {
        left: 3vh;
        flex-direction: column;
        gap: 3vh;
        bottom: 20vh;
        align-items: flex-start;
      }
    }

    .part2 {
      width: 100vw;
      min-width: 100vw;
      padding-left: 0;
      flex-direction: column;
      min-height: 100vh;
      height: auto;

      .bow {
        svg {
          height: 45vh;
        }
      }

      .content {
        display: flex;
        align-items: center;
        gap: 6vh;

        ul {
          display: flex;
          flex-direction: column;
          gap: 6vh;

          li {
            color: $--primaryBlue;

            font-size: 24px;

            span {
              font-weight: 600;
            }
            &:nth-child(2),
            &:nth-child(8) {
              margin-left: unset;
            }
            &:nth-child(3),
            &:nth-child(7) {
              margin-left: unset;
            }
            &:nth-child(4),
            &:nth-child(6) {
              margin-left: unset;
            }
            &:nth-child(5) {
              margin-left: unset;
            }
          }
        }
      }
    }
  }
}
