@import '../../assets/style/variables.module.scss';

.nasilOlur {
  width: 200vw;
  height: 100vh;
  min-width: 200vw;
  display: flex;
  position: relative;

  .part1 {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    display: flex;
    padding-left: 20vh;
    padding-top: 8.8vh;
    position: relative;
    box-sizing: border-box;

    .top {
      display: flex;
      flex-direction: column;
      gap: 5.19vh;

      .logo {
        svg {
          width: 13.5vh;
          height: 4.2vh;
        }
      }

      .svgWrapper {
        display: flex;
        flex-direction: column;

        svg {
          &:nth-child(1) {
            width: 43vh;
            height: 7.7vh;
          }
          &:nth-child(2) {
            margin-left: 5.2vh;
            width: 90vh;
            height: 27vh;
          }
        }
      }
    }

    .info {
      display: flex;
      position: absolute;
      bottom: 8vh;
      left: 20vh;
      gap: 7vh;

      p {
        color: $--text-color;
        font-size: 2.3vh;
        line-height: 2.7vh;
        font-weight: 400;
      }

      a {
        color: $--primaryBlue;
        text-decoration: underline;
      }
    }
  }

  .part2 {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    padding-left: 20vh;
    padding-top: 8.8vh;
    padding-bottom: 8.8vh;
    position: relative;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 20vh;

    .cardWrapper {
      display: flex;
      width: 100%;
      align-items: flex-start;
      position: relative;
      .card {
        width: 33vh;
        height: 29.5vh;
        background: white;
        padding: 3vh;
        box-sizing: border-box;
        position: absolute;
        color: $--primaryBlue;
        font-size: 1.9vh;
        line-height: 2.3vh;
        font-weight: 400;

        &:nth-child(2) {
          left: 30vh;
          top: 10vh;
        }
        &:nth-child(3) {
          left: 61vh;
          top: 5vh;
        }
      }
    }

    .bottomSvg {
      svg {
        width: 73vh;
        height: 29vh;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .nasilOlur {
    width: 100vw;
    height: auto;
    max-height: auto;
    flex-direction: column;

    .part1 {
      padding: 3vh;
      height: 100vh;
      min-height: 100vh;

      .top {
        .svgWrapper {
          svg {
            &:nth-child(1) {
              height: 3vh;
              width: 18vh;
            }
            &:nth-child(2) {
              height: 30vw;
              width: 80vw;
              margin-left: 3vh;
            }
          }
        }
      }

      .info {
        left: 3vh;
        flex-direction: column;
        gap: 3vh;
        bottom: 30vh;
        align-items: flex-start;
      }
    }

    .part2 {
      width: 100vw;
      min-width: 100vw;
      padding-left: 0;
      flex-direction: column;
      min-height: 150vh;
      height: auto;
      background: #f7f3ee;
      position: relative;
      z-index: 2;
      padding-right: 0;
      width: 100vw;

      .cardWrapper {
        display: flex;
        width: 100%;
        align-items: flex-start;
        position: relative;
        .card {
          width: 33vh;
          height: 29.5vh;
          background: white;
          padding: 3vh;
          box-sizing: border-box;
          position: absolute;
          color: $--primaryBlue;
          font-size: 1.9vh;
          line-height: 2.3vh;
          font-weight: 400;

          &:nth-child(1) {
            left: 5vh;
            top: 60vh;
          }

          &:nth-child(2) {
            right: 3vh;
            left: unset;
            top: 32vh;
          }
          &:nth-child(3) {
            left: 3vh;
            top: 5vh;
          }
        }
      }

      .bottomSvg {
        width: 100%;
        padding-left: 3vh;
        box-sizing: border-box;
        svg {
          width: 36vh;
          height: 16vh;
        }
      }
    }
  }
}
