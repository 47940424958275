@import '../../../assets/style/variables.module.scss';

.cardWrapper {
  position: fixed;
  left: 0;
  z-index: 999;
  left: -100vh;
  top: 30vh;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    .content {
      left: 11vh;
    }
  }
}

.active {
  left: 3.6vh;
}

.card {
  background: #019542;
  display: flex;
  gap: 2vh;
  padding: 2vh;
  transform: rotate(90deg);
  border: 0.2vh solid #f7f3ee;
  align-items: center;
  left: -1vh;
  position: relative;

  svg {
    width: 16px;
    height: 16px;
  }

  h2 {
    color: #f7f3ee;
  }
}

.content {
  position: absolute;
  left: -60vh;
  background: $--primaryBlue;
  padding: 2vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 23vh;
  top: -5.38vh;
  border: 0.2vh solid #f7f3ee;
  transition: all 0.5s ease;
  p {
    color: #f7f3ee;
    font-size: 2vh;
    font-weight: 400;
    line-height: 2.7vh;
    letter-spacing: 0.02em;
  }

  button {
    background: $--secondaryGreen;
    color: $--primaryBlue;
    padding: 1.2vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1vh;
    margin-top: 1.5vh;
    font-size: 1.5vh;
  }
}

@media only screen and (max-width: 940px) {
  .active {
    right: 1vh;
    left: unset;
  }

  .cardWrapper {
    bottom: 9vh;
    top: unset;

    .content {
      display: none;
    }

    .card {
      transform: rotate(0);
    }
  }
}
