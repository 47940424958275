@import '../../assets/style/variables.module.scss';

.bayetavInfo {
  width: 200vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  box-sizing: border-box;

  .imageGrid {
    width: 100vw;
    min-width: 100vw;
    display: grid;
    box-sizing: border-box;
    padding: 100px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;

    div {
      display: grid;
      grid-template-columns: 1fr;
      gap: 50px;
      width: 80%;

      img {
        width: 100%;
      }
    }
  }

  .info {
    min-width: 100vw;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 50px;
    padding-left: 130px;

    .left {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;

      svg {
        height: 50px;
        width: 100px;
      }

      img {
        width: 560px;
        height: 410px;
        object-fit: cover;
        object-position: center;
        margin-top: 20px;
      }

      p {
        color: $--text-color;
        width: 500px;
        font-size: 16px;
        line-height: 20px;
        margin-top: 30px;
      }

      ul {
        display: flex;
        gap: 50px;
        position: absolute;
        bottom: 0;
        left: 0;

        li {
          h5 {
            font-size: 14px;
            font-weight: 400;
            color: #979797;
            text-wrap: nowrap;
          }

          h6 {
            font-size: 22px;
            text-wrap: nowrap;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .infoGrid {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 40%;
  }
}
