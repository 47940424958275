@import '../../assets/style/variables.module.scss';

.butunselEgitim {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $--primaryBlue;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  position: relative;

  .content {
    position: relative;

    h2 {
      position: absolute;
      color: #c0d672;
      font-family: $--Kalnia;
      font-weight: 400;
      font-size: 9vh;

      &:nth-child(1) {
        left: -15.5vh;
        top: 0;
      }
      &:nth-child(3) {
        right: -3vh;
        bottom: 0;
      }
    }
  }

  .svgWrapper {
    svg {
      width: 80vh;
      height: 80vh;
    }
  }
}

@media only screen and (max-width: 940px) {
  .butunselEgitim {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    height: 50vh;
    min-height: 50vh;
    max-height: 50vh;
    .content {
      scale: 0.4;
    }
  }
}
