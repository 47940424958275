@import '../../assets/style/variables.module.scss';

.card {
  background: $--green;
  padding: 30px;
  box-sizing: border-box;
  height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  width: calc(100vw - 200px);
  max-width: calc(100vw - 200px);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  border-radius: 20px;
  transform: translateY(-80px);
}
