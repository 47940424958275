@import '../../assets/style/variables.module.scss';

.nitelikli {
  width: 200vw;
  min-width: 200vw;
  max-width: 200vw;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  padding-left: 20vh;
  padding-top: 8.8vh;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  .svgWrapper {
    position: relative;

    .mainSvg {
      svg {
        height: 50vh;
        width: 230vh;
      }
    }

    .card {
      position: absolute;
      background: white;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        height: 100%;
        padding: 3vh 5vh;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .nitelikli {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;

    .svgWrapper {
      scale: 0.5;
      margin-bottom: 15vh;
      width: 100%;

      .mainSvg {
        svg {
          scale: 0.4;
        }
      }
    }
  }
}
