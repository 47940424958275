@import '../../assets/style/variables.module.scss';

.imageWrapper {
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  position: relative;
  display: flex;

  .cardWrapper {
    position: absolute;
    bottom: 14vh;
    right: 7vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .card {
      background: white;

      font-size: 2.7vh;
      line-height: 3vh;
      color: $--primaryBlue;
      padding: 1.6vh;
      border: 0.2vh solid $--primaryBlue;
      text-align: right;

      span {
        font-weight: 600;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@media only screen and (max-width: 940px) {
  .imageWrapper {
    width: 100vw;
    min-width: 100vw;
    height: 70vh;
    max-height: 70vh;
    min-height: unset;
    position: static;
    position: relative;
    .cardWrapper {
      bottom: 5vh;
      right: 5vw;

      .card {
        font-size: 2vh;
        line-height: 2.5vh;
      }
    }
  }
}
