@import '../../assets/style/variables.module.scss';

.popup {
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba($color: $--primaryBlue, $alpha: 0.8);

  .content {
    width: 50vw;
    background: #f7f3ee;
    height: 70vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 3vh;
    position: relative;

    .textContent {
      width: 100%;
      overflow: scroll;
      padding: 5vh 7vh;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 3vh;
    }

    .topRow {
      position: absolute;
      height: 4vh;
      top: -4vh;
      background: #d6d2cc;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 2vh;

      h3 {
        font-family: $--Kalnia;
        font-weight: 400;
        color: $--primaryBlue;
      }

      button {
        height: 2vh;
        width: 2vh;
        svg {
          width: 100%;
          height: 100%;

          line {
            stroke: $--primaryBlue;
          }
        }
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      gap: 2vh;

      h2 {
        font-family: $--Kalnia;
        font-size: 2.4vh;
        font-weight: 400;
        color: $--primaryBlue;
        line-height: 3.5vh;
      }

      p {
        font-size: 1.8vh;
        line-height: 2.3vh;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .popup {
    height: 100%;
    min-height: unset;
    max-height: unset;
    .content {
      width: 90vw;
      height: 70vh;
      top: 3vh;

      .textContent {
        padding: 6vh 4vh;
      }

      .topRow {
        height: 7vh;
      }
    }
  }
}
