@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans+Condensed:ital,wght@0,1..1000;1,1..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalnia:wght@100..700&family=Sofia+Sans+Condensed:ital,wght@0,1..1000;1,1..1000&display=swap');

$--green: #b9d36c;
$--orange: #fab648;
$--purple: #a171af;
$--blue: #779fd4;

$--text-color: #686868;

$--primaryBlue: #0055b8;
$--secondaryGreen: #c0d672;

$--Kalnia: 'Kalnia', serif;

body {
  font-family: 'Sofia Sans Condensed', sans-serif;
  overflow: hidden;
  background: #f7f3ee;
  scroll-behavior: smooth;
}

textarea,
input,
select {
  font-family: 'Sofia Sans Condensed', sans-serif;
  letter-spacing: 0.04em;
}
button {
  font-family: 'Sofia Sans Condensed', sans-serif !important;

  background: transparent;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
