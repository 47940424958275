@import '../../../assets/style/variables.module.scss';

.discover {
  position: absolute;
  bottom: 8vh;
  display: flex;
  align-items: center;
  gap: 0.5vh;
  right: 9vh;
  cursor: pointer;
  z-index: 999;

  h5 {
    font-size: 2.6vh;
    font-weight: 400;
    color: $--primaryBlue;
  }
}

@media only screen and (max-width: 940px) {
  .discover {
    bottom: 20vh;
    right: 5vw;

    h5 {
      font-size: 2vh;
    }

    svg {
      transform: rotate(90deg);
    }
  }
}
