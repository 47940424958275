@import '../../assets/style/variables.module.scss';

.intro {
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;

  .svgWrapper {
    position: relative;
    z-index: 2;
  }
}

.logoWrapper {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;

  svg {
    width: 244px;
    height: 80px;
  }
}

.bgImage {
  position: absolute;

  width: 100vw;
  height: 100vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: center;
  }
}

.rectangle {
  height: 100vh;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: $--primaryBlue;
}

.rectangle2 {
  height: 100vh;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #f7f3ee;
}

@media only screen and (max-width: 940px) {
  .intro {
    .svgWrapper {
      svg {
        scale: 0.3;
      }
    }
  }
}
