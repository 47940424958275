@import '../../assets/style/variables.module.scss';

.stepper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7vh;

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: all 0.5s ease;

    .number {
      font-family: $--Kalnia;
      font-size: 3.2vh;
      border: 1px solid $--secondaryGreen;
      width: 4.5vh;
      height: 4.5vh;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $--secondaryGreen;
      transition: all 0.5s ease;
    }

    p {
      text-align: center;
      font-family: $--Kalnia;
      color: $--secondaryGreen;
      position: absolute;
      bottom: -6vh;
      font-size: 1.7vh;
      line-height: 2.3vh;
      transition: all 0.5s ease;
    }
  }

  .active {
    .number {
      background: $--secondaryGreen;
      color: white;
    }
    p {
      display: none;
    }
  }

  .stroke {
    width: 20vh;
    height: 0.1vh;
    background: $--secondaryGreen;
  }
}

@media only screen and (max-width: 940px) {
  .stepper {
    width: 100%;

    .stroke {
      width: 5vh;
    }
  }
}
