@import '../../../assets/style/variables.module.scss';

.backToStory {
  position: absolute;
  bottom: 8vh;
  display: flex;
  align-items: center;
  gap: 0.5vh;
  left: 20vh;
  cursor: pointer;

  svg {
    transform: rotate(180deg);
  }

  h5 {
    font-size: 2.6vh;
    font-weight: 400;
    color: $--primaryBlue;
  }
}

@media only screen and (max-width: 940px) {
  .backToStory {
    position: static;
    margin-top: 2vh;

    h5 {
      font-size: 2vh;
    }
  }
}
