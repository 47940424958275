@import '../../assets/style/variables.module.scss';

.ilkelerimiz {
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  background: $--primaryBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      color: white;
      font-size: 4.5vh;
      position: absolute;
      top: 8.8vh;
      left: 15vh;
      z-index: 1;
      font-family: $--Kalnia;
      font-weight: 400;
    }
  }

  .circleWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        font-family: $--Kalnia;
        font-weight: 500;
        color: $--primaryBlue;
        text-align: center;
        position: absolute;
        font-size: 2.5vh;
        line-height: 3.4vh;
      }

      &:nth-child(1) {
        h3 {
          top: 5.4vh;
        }

        span {
          width: 89vh;
          height: 89vh;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      &:nth-child(2) {
        margin-top: 13vh;
        h3 {
          top: 10vh;
          left: 12vh;
          font-size: 2.1vh;
          line-height: 3vh;
        }

        span {
          width: 69vh;
          height: 69vh;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      &:nth-child(3) {
        margin-top: 20vh;
        margin-left: 10vh;
        h3 {
          top: 10vh;
          right: 7vh;
        }

        span {
          width: 50vh;
          height: 50vh;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      &:nth-child(4) {
        margin-top: 25vh;

        span {
          width: 30vh;
          height: 30vh;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .ilkelerimiz {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    height: 60vh;
    min-height: 80vh;
    .content {
      h2 {
        left: unset;
      }
    }

    .circleWrapper {
      scale: 0.5;
    }
  }
}
