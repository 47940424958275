@import '../../assets/style/variables.module.scss';

.nasilOgretmen {
  width: 200vw;
  height: 100vh;
  min-width: 200vw;
  display: flex;
  position: relative;

  .part1 {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    display: flex;
    padding-left: 20vh;
    padding-top: 8.8vh;
    position: relative;
    box-sizing: border-box;

    .top {
      display: flex;
      flex-direction: column;
      gap: 5.19vh;

      .logo {
        svg {
          width: 13.5vh;
          height: 4.2vh;
        }
      }

      .svgWrapper {
        display: flex;
        flex-direction: column;

        svg {
          &:nth-child(1) {
            width: 43vh;
            height: 7.7vh;
          }
          &:nth-child(2) {
            margin-left: 5.2vh;
            width: 90vh;
            height: 27vh;
          }
        }
      }
    }

    .info {
      display: flex;
      position: absolute;
      bottom: 8vh;
      left: 20vh;
      gap: 7vh;

      p {
        color: $--text-color;
        font-size: 2.3vh;
        line-height: 2.7vh;
        font-weight: 400;

        a {
          cursor: pointer;
          color: $--primaryBlue;
          text-decoration: underline;

          svg {
            margin-left: 1vh;
            transform: rotate(180deg);
            path {
              fill: $--primaryBlue;
            }
          }
        }
      }
    }
  }

  .part2 {
    display: flex;
    background: $--primaryBlue;
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;

    p {
      font-size: 5vh;
      line-height: 7vh;
      font-weight: 400;
      color: white;

      span {
        font-weight: 600;
        border-bottom: 0.4vh solid red;
      }
    }
  }
}
@media only screen and (max-width: 940px) {
  .nasilOgretmen {
    width: 100vw;
    height: auto;
    max-height: auto;
    flex-direction: column;

    .part1 {
      padding: 3vh;
      min-height: 100vh;
      max-height: 90vh;
      min-height: 90vh;
      height: 90vh;

      .top {
        .svgWrapper {
          svg {
            &:nth-child(1) {
              height: 3vh;
              width: 18vh;
            }
            &:nth-child(2) {
              height: 30vw;
              width: 80vw;
              margin-left: 3vh;
            }
          }
        }
      }

      .info {
        left: 3vh;
        flex-direction: column;
        gap: 3vh;
        bottom: 20vh;
      }
    }

    .part2 {
      width: 100vw;
      min-width: 100vw;
      padding-left: 0;
      background: $--primaryBlue;
      flex-direction: column;
      min-height: 60vh;
      height: auto;
      padding: 0 3vh;

      p {
        font-size: 18px;
        line-height: 34px;

        span {
          border-color: $--secondaryGreen;
        }
      }
    }
  }
}
