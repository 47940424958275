@import '../../assets/style/variables.module.scss';

.ogretmenBasvur {
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  padding-bottom: 10vh;
  box-sizing: border-box;

  .content {
    padding-left: calc(10vh + 89px);
    box-sizing: border-box;
    padding-top: 75px;
    width: 100vw;
    display: flex;

    h1 {
      font-size: 42px;
      font-family: $--Kalnia;
      font-weight: 500;
      color: $--primaryBlue;
    }

    .cardWrapper {
      width: 775px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 50px;
    }
  }
}

.faqCard {
  width: 100%;
  background: white;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    h4 {
      font-size: 22px;
      color: $--primaryBlue;
      font-weight: 500;
    }

    button {
      svg {
        transition: all 0.5s ease;
        path {
          transition: all 0.5s ease;
        }
      }
    }
  }

  p {
    font-size: 18px;
    line-height: 26px;
    padding-left: 10px;
    max-width: 540px;
    border-left: 1px solid #b9d36c;
    opacity: 0;
    transition: all 0.5s ease;
    height: 0;
  }
}

.active {
  .top {
    button {
      svg {
        transform: rotate(90deg);

        path {
          fill: #b9d36c;
        }
      }
    }
  }

  p {
    opacity: 1;
    height: auto;
    margin-top: 20px;
  }
}

.faqDesign {
  z-index: -1;
  position: relative;
  width: 100%;

  svg {
    &:nth-child(1) {
      position: absolute;
      left: 0;
      top: 200px;
    }
    &:nth-child(2) {
      position: absolute;
      right: 0;
      top: 600px;
    }
    &:nth-child(3) {
      position: absolute;
      left: 0;
      top: 1000px;
    }
    &:nth-child(4) {
      position: absolute;
      right: 0;
      top: 1600px;
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ldsRipple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  scale: 3;
}
.ldsRipple div {
  position: absolute;
  border: 4px solid $--secondaryGreen;
  opacity: 1;
  border-radius: 50%;
  animation: ldsRipple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldsRipple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes ldsRipple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media only screen and (max-width: 940px) {
  .ogretmenBasvur {
    .content {
      padding-left: unset;
      padding: 40px 20px;
      padding-bottom: 150px;

      h1 {
        font-size: 34px;
        line-height: 44px;
      }
      .cardWrapper {
        width: 100%;
        margin-top: 25px;
      }
    }

    .faqCard {
      .top {
        h4 {
          font-size: 18px;
          line-height: 22px;
        }
      }

      p {
        font-size: 16px;
      }
    }
  }

  .faqDesign {
    display: none;
  }
}
