@import '../../assets/style/variables.module.scss';

.birAradaYasariz {
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  background: $--primaryBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 40vh;
    background: linear-gradient(
      180deg,
      rgba(0, 85, 184, 0) 0%,
      rgba(0, 85, 184, 1) 54%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &::after {
    content: '';
    width: 100%;
    height: 40vh;
    background: linear-gradient(
      0,
      rgba(0, 85, 184, 0) 0%,
      rgba(0, 85, 184, 1) 54%
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 4.2vh;

    position: relative;

    h2 {
      font-size: 8.5vh;
      color: white;
      font-weight: 700;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 1vh;
      transform: translateY(-4.5vh);
      max-width: 15vw;
      animation: animation 7s infinite;

      .mainText {
        color: $--secondaryGreen;
        font-weight: 600;
      }
      li {
        font-size: 8.5vh;
        color: white;
        font-weight: 300;
        transition: all 0.1s ease;

        // &:nth-child(1),
        // &:nth-child(7) {
        //   opacity: 0.2;
        // }

        // &:nth-child(4) {
        //   color: $--secondaryGreen;
        // }
      }
    }

    $--keyVal: -10.5 * 1.8vh;

    @keyframes animation {
      0% {
        margin-top: 0;
      }
      14.2% {
        margin-top: $--keyVal;
      }

      28.4% {
        margin-top: $--keyVal * 2;
      }
      42.6% {
        margin-top: $--keyVal * 3;
      }

      56.8% {
        margin-top: $--keyVal * 4;
      }

      71% {
        margin-top: $--keyVal * 5;
      }
      85.2% {
        margin-top: $--keyVal * 6;
      }
      100% {
        margin-top: $--keyVal * 7;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .birAradaYasariz {
    overflow: hidden;
    max-height: 100vh;

    .content {
      width: 100vw;
      min-width: 100vw;
      max-height: 90vh;
      gap: 0px;
      scale: 0.7;
      gap: 0;

      ul {
        max-width: unset;
      }

      h2 {
        text-wrap: nowrap;
        white-space: nowrap;
        left: -3vh;
        position: relative;
      }
    }
  }
}
