@import '../../assets/style/variables.module.scss';

.ogretmenBasvur {
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  padding-bottom: 10vh;
  box-sizing: border-box;

  .top {
    margin-bottom: 40px;
    padding: 0vh 15vh;
    padding-top: 5vh;
    box-sizing: border-box;

    h2 {
      font-size: 26px;
      color: $--primaryBlue;
      font-family: $--Kalnia;
      font-weight: 400;
      margin-top: 20px;
    }

    .column {
      margin-top: 30px;
    }

    .row {
      display: flex;
      gap: 100px;

      .column {
        width: 50%;

        h3 {
          font-size: 22px;
          color: $--primaryBlue;
          margin-bottom: 15px;
        }

        p {
          font-size: 16px;
          line-height: 22px;
        }

        ul {
          line-height: 22px;
          list-style: disc;
          li {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .ogretmenBasvur {
    h2 {
      line-height: 32px;
    }
    .top {
      flex-direction: column;
      padding: 3vh 4vh;

      .row {
        flex-direction: column;
        width: 100%;
        gap: 10px;

        .column {
          width: 100%;
        }
      }
    }
  }
}
